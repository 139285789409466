import { Fragment } from 'react';
import { Settings } from './Settings/Settings';
import classes from './Header.module.css';
import { useAppSelector } from '../../store';

export const Header = () => {
  const backgroundColor = useAppSelector((state) => state.styles.backgroundColor);
  const textColorOnBackground = useAppSelector((state) => state.styles.textColorOnBackground);

  return (
    <Fragment>
      <Settings />
      <div
        className={classes.main}
        style={{ backgroundColor: backgroundColor, color: textColorOnBackground }}
      >
        <h1 className={classes.title} style={{ color: textColorOnBackground }}>
          The one thing
        </h1>
      </div>
    </Fragment>
  );
};
