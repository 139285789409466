import classes from './ColorInput.module.css';
import { ChangeEvent, Dispatch } from 'react';

interface ColorInputProps {
  setColor: Dispatch<string>;
  currentColor: string;
  title: string;
}

export const ColorInput = ({ setColor, currentColor, title }: ColorInputProps) => {
  const changeValue = (e: ChangeEvent<HTMLInputElement>) => {
    setColor(e.target.value);
  };

  return (
    <div className={classes.main_container}>
      <span className={classes.title}>{title}:</span>
      <input
        type="color"
        value={currentColor}
        onChange={changeValue}
        className={classes.color_input}
      />
    </div>
  );
};
