import classes from './NumberInput.module.css';

interface NumberInputProps {
  onChange: (value: number) => void;
  onIncrement: () => void;
  onDecrement: () => void;
  title: string;
  className?: string;
  value: number;
}

export const NumberInput = ({
  onChange,
  onIncrement,
  onDecrement,
  title,
  value,
  className,
}: NumberInputProps) => {
  return (
    <div className={classes.main + ' ' + className}>
      <div className={classes.description}>
        <h4 className={classes.title}>{title}</h4>
        <p className={classes.note}>(in minutes)</p>
      </div>
      <div className={classes.input_container}>
        <button onClick={onDecrement}>-</button>
        <input
          className={classes.numberInput}
          type="number"
          min="1"
          step="1"
          value={value}
          onChange={(e) => onChange(parseInt(e.target.value))}
        ></input>
        <button onClick={onIncrement}>+</button>
      </div>
    </div>
  );
};
