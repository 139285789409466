import { createSlice } from "@reduxjs/toolkit";

const initialStylesState = {
  backgroundColor: "#3d0280",
  textColorOnBackground: "#FFFFFF",
  fontFamily: "'Inconsolata', monospace"
};

const stylesSlice = createSlice({
  name: "styles",
  initialState: initialStylesState,
  reducers: {
    setBackgroundColor(state, action) {
      state.backgroundColor = action.payload;
    },
    setTextColorOnBackground(state, action) {
      state.textColorOnBackground = action.payload;
    },
    setFontFamily(state, action) {
      state.fontFamily = action.payload;
    }
  }
});

export const stylesActions = stylesSlice.actions;
export default stylesSlice.reducer;
