import { createSlice } from '@reduxjs/toolkit';
import { Gif } from '../models/Gif';

interface FocusType {
  name: string;
  focusLength: number;
  shortBreakLength: number;
  longBreakLength: number;
  selectedGif: Gif | undefined;
  sessionIsStarted: boolean;
}

const initialFocusState: FocusType = {
  name: '',
  focusLength: 25,
  shortBreakLength: 5,
  longBreakLength: 60,
  selectedGif: undefined,
  sessionIsStarted: false,
};

const focusSlice = createSlice({
  name: 'focus',
  initialState: initialFocusState,
  reducers: {
    setFocusName(state, action) {
      state.name = action.payload;
    },
    setFocusLength(state, action) {
      state.focusLength = action.payload;
    },
    setShortBreakLength(state, action) {
      state.shortBreakLength = action.payload;
    },
    setLongBreakLength(state, action) {
      state.longBreakLength = action.payload;
    },
    setSelectedGif(state, action: { payload: Gif | undefined }) {
      state.selectedGif = action.payload;
    },
    setSessionIsStarted(state) {
      state.sessionIsStarted = !state.sessionIsStarted;
    },
  },
});

export const focusActions = focusSlice.actions;
export default focusSlice.reducer;
