import { ChangeEvent, useCallback, useState } from 'react';
import { focusActions } from '../../store/focusSlice';
import { GifButton } from './GifButton';
import classes from './GifSearch.module.css';
import { useAppDispatch, useAppSelector } from '../../store';
import { GiphyResponse, GiphyResult } from '../../models/Gif';

const giphyApiKey = process.env.REACT_APP_GIPHY_API_KEY || '';

export const GifSearch = () => {
  const dispatch = useAppDispatch();
  const currentGif = useAppSelector((state) => state.focus.selectedGif);

  const [searchResults, setSearchResults] = useState<GiphyResult[]>();

  const onValueChange = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(focusActions.setSelectedGif(undefined));
    void fetchGifsHandler(e.target.value);
  };

  const fetchGifsHandler = useCallback(async (searchValue: string) => {
    const url = `https://api.giphy.com/v1/gifs/search?api_key=${giphyApiKey}&q=$${searchValue}&limit=6&offset=0&rating=g&lang=en`;
    const response = await fetch(url);
    const convertedGifs: GiphyResponse = await response.json();
    setSearchResults(convertedGifs.data);
  }, []);

  return (
    <div className={classes.main}>
      <h4>Choose a gif to celebrate</h4>
      <input type="text" onChange={onValueChange} className={classes.searchbar} />
      {searchResults && !currentGif && (
        <div className={classes.gif_container}>
          {searchResults.map((gif: GiphyResult) => (
            <GifButton
              key={gif.id}
              src={gif.images.fixed_height.url}
              alt={gif.title}
              onClick={() =>
                dispatch(
                  focusActions.setSelectedGif({
                    src: gif.images.fixed_height.url,
                    alt: gif.title,
                  })
                )
              }
            />
          ))}
        </div>
      )}
    </div>
  );
};
