import { useState } from 'react';
import { Quotes } from './Quotes';
import classes from './Session.module.css';
import { useAppSelector } from '../../../store';
import { FocusTimer } from './FocusTimer/FocusTimer';
import { ShortBreakTimer } from './ShortBreakTimer/ShortBreakTimer';

interface FocusProps {
  finishedFocuses: number;
  onFocusFinish: () => void;
}

export const Session = ({ onFocusFinish, finishedFocuses }: FocusProps) => {
  const focusName = useAppSelector((state) => state.focus.name);

  const [isFocusActive, setIsFocusActive] = useState(true);

  return (
    <>
      <h3 className={classes.finished_focuses}>Finished focuses: {finishedFocuses}</h3>
      <h2 className={classes.title}>{focusName}</h2>
      {isFocusActive ? (
        <FocusTimer
          onFinish={() => {
            onFocusFinish();
            setIsFocusActive(false);
          }}
        />
      ) : (
        <ShortBreakTimer onFinish={() => setIsFocusActive(true)} />
      )}
      <Quotes />
    </>
  );
};
