import { Timer } from '../Focus/Timer';
import classes from './LongBreak.module.css';
import { useAppSelector } from '../../../store';

interface LongBreakProps {
  onFinish: () => void;
}

export const LongBreak = ({ onFinish }: LongBreakProps) => {
  const length = useAppSelector((state) => state.focus.longBreakLength);
  const gif = useAppSelector((state) => state.focus.selectedGif);

  return (
    <div className={classes.main}>
      <h2 className={classes.title}>It's time for a long break</h2>
      <div className={classes.gif}>
        <img src={gif?.src} alt={gif?.alt} />
      </div>
      <Timer lengthInSec={length * 60} isRunning onFinish={onFinish} />
    </div>
  );
};
