import { useEffect, useState } from 'react';
import styles from './Timer.module.css';
import alert from '../../../assets/pristine.mp3';
import { useAppSelector } from '../../../store';

interface TimerProps {
  lengthInSec: number;
  isRunning: boolean;
  onFinish: () => void;
}

export const Timer = ({ lengthInSec, isRunning, onFinish }: TimerProps) => {
  const [secondsLeft, setSecondsLeft] = useState(lengthInSec);

  const backgroundColor = useAppSelector((state) => state.styles.backgroundColor);
  const minutes = Math.floor(secondsLeft / 60);
  const seconds = secondsLeft % 60;
  const rotationAngle = 1.8 * ((100 * secondsLeft) / lengthInSec);
  const alertSound = new Audio(alert);

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;

    if (isRunning && secondsLeft > 0) {
      interval = setInterval(() => {
        setSecondsLeft((prevTime) => prevTime - 1);
      }, 1000);
    } else if (secondsLeft === 0) {
      alertSound.volume = 0.5;
      alertSound.play().then(() => setTimeout(onFinish, 600));
    }

    return () => clearInterval(interval!);
  }, [isRunning, secondsLeft]);

  return (
    <div className={styles.timer_container}>
      <div className={styles.progress_circle}>
        <div>
          <div
            className={styles.progress_circle__slice}
            style={{ transform: `rotate(${rotationAngle}deg)` }}
          >
            <div
              className={styles.progress_circle__fill}
              style={{ transform: `rotate(${rotationAngle}deg)`, backgroundColor }}
            ></div>
          </div>
          <div className={styles.progress_circle__slice}>
            <div
              className={styles.progress_circle__fill}
              style={{ transform: `rotate(${rotationAngle}deg)`, backgroundColor }}
            ></div>
            <div
              className={`${styles.progress_circle__fill} ${styles.progress_circle__slice}`}
              style={{
                transform: `rotate(${rotationAngle * 2}deg)`,
                backgroundColor,
              }}
            ></div>
          </div>
        </div>
        <div className={styles.progress_circle__overlay}>
          <div className={styles.progress_circle__value} style={{ color: backgroundColor }}>
            {minutes}:{seconds < 10 ? '0' : ''}
            {seconds}
          </div>
        </div>
      </div>
    </div>
  );
};
