import { SetUpSession } from './components/SetUpSession/SetUpSession';
import { OngoingSession } from './components/OngoingSession/OngoingSession';
import { Header } from './components/header/Header';
import { useAppSelector } from './store';

export const App = () => {
  const sessionIsStarted = useAppSelector((state) => state.focus.sessionIsStarted);
  const fontFamily = useAppSelector((state) => state.styles.fontFamily);

  return (
    <div style={{ fontFamily: fontFamily }}>
      <Header />
      {sessionIsStarted ? <OngoingSession /> : <SetUpSession />}
    </div>
  );
};
