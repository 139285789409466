import { useState } from 'react';
import classes from './NameInput.module.css';

interface NameInputProps {
  onChange: (value: string) => void;
}

export const NameInput = ({ onChange }: NameInputProps) => {
  const [focusName, setFocusName] = useState('');

  return (
    <div className={classes.main}>
      <h2>
        What is the subject of your focus? <br /> <span className={classes.note}>(optional)</span>
      </h2>
      <input
        type="text"
        name="focus"
        value={focusName}
        onChange={(e) => {
          setFocusName(e.target.value);
          onChange(e.target.value);
        }}
        className={classes.focus_input}
      />
    </div>
  );
};
