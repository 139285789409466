import { Timer } from '../Timer';
import { useAppSelector } from '../../../../store';
import { Button } from '../../../ui/Button';
import styles from './ShortBreakTimer.module.css';
import { useState } from 'react';

interface ShortBreakTimerProps {
  onFinish: () => void;
}

export const ShortBreakTimer = ({ onFinish }: ShortBreakTimerProps) => {
  const length = useAppSelector((state) => state.focus.shortBreakLength);

  const [isRunning, setIsRunning] = useState(false);

  return (
    <div>
      <h2 className={styles.title}>Short break</h2>
      <Timer lengthInSec={length * 60} isRunning={isRunning} onFinish={onFinish} />
      <Button onClick={() => setIsRunning((prevState) => !prevState)}>
        {isRunning ? 'Pause' : 'Start'}
      </Button>
    </div>
  );
};
