import { focusActions } from '../../store/focusSlice';
import { NameInput } from './NameInput';
import { NumberInput } from './NumberInput';
import { GifSearch } from './GifSearch';
import { Button } from '../ui/Button';
import classes from './SetUpSession.module.css';
import { useAppDispatch, useAppSelector } from '../../store';

export const SetUpSession = () => {
  const dispatch = useAppDispatch();

  const currentGif = useAppSelector((state) => state.focus.selectedGif);
  const focusLength = useAppSelector((state) => state.focus.focusLength);
  const shortBreakLength = useAppSelector((state) => state.focus.shortBreakLength);
  const longBreakLength = useAppSelector((state) => state.focus.longBreakLength);

  return (
    <>
      <NameInput onChange={(value: string) => dispatch(focusActions.setFocusName(value))} />
      <div className={classes.number_inputs}>
        <NumberInput
          title="Set the duration of your focus session"
          value={focusLength}
          onChange={(value: number) => dispatch(focusActions.setFocusLength(value))}
          onIncrement={() => dispatch(focusActions.setFocusLength(focusLength + 1))}
          onDecrement={() => dispatch(focusActions.setFocusLength(focusLength - 1))}
        />
        <NumberInput
          title="Set the duration of your short break"
          value={shortBreakLength}
          onChange={(value: number) => dispatch(focusActions.setShortBreakLength(value))}
          onIncrement={() => dispatch(focusActions.setShortBreakLength(shortBreakLength + 1))}
          onDecrement={() => dispatch(focusActions.setShortBreakLength(shortBreakLength - 1))}
        />
        <NumberInput
          title="Set the duration of your long break"
          value={longBreakLength}
          onChange={(value: number) => dispatch(focusActions.setLongBreakLength(value))}
          onIncrement={() => dispatch(focusActions.setLongBreakLength(longBreakLength + 1))}
          onDecrement={() => dispatch(focusActions.setLongBreakLength(longBreakLength - 1))}
        />
      </div>
      <GifSearch />
      {currentGif && (
        <>
          <img src={currentGif.src} alt={currentGif.alt} className={classes.gif} />
          <Button
            className={classes.submit_button}
            onClick={() => dispatch(focusActions.setSessionIsStarted())}
          >
            Start Session
          </Button>
        </>
      )}
    </>
  );
};
