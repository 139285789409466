import { useEffect, useState } from 'react';
import { Session } from './Focus/Session';
import { LongBreak } from './LongBreak/LongBreak';

export const OngoingSession = () => {
  const [finishedFocuses, setFinishedFocuses] = useState(0);
  const [isActiveSession, setIsActiveSession] = useState(true);

  useEffect(() => {
    if (finishedFocuses > 0 && finishedFocuses % 3 === 0) {
      setIsActiveSession(false);
    }
  }, [finishedFocuses]);

  return (
    <>
      {isActiveSession ? (
        <Session
          finishedFocuses={finishedFocuses}
          onFocusFinish={() => setFinishedFocuses((prevState) => prevState + 1)}
        />
      ) : (
        <LongBreak onFinish={() => setIsActiveSession(true)} />
      )}
    </>
  );
};
