import { stylesActions } from '../../store/stylesSlice';
import classes from './FontFamilyInput.module.css';
import { useAppDispatch, useAppSelector } from '../../store';
import { ChangeEvent } from 'react';

export const FontFamilyInput = () => {
  const dispatch = useAppDispatch();

  const fontFamily = useAppSelector((state) => state.styles.fontFamily);
  const backgroundColor = useAppSelector((state) => state.styles.textColorOnBackground);
  const textColor = useAppSelector((state) => state.styles.backgroundColor);

  const setFontFamily = (e: ChangeEvent<HTMLSelectElement>) =>
    dispatch(stylesActions.setFontFamily(e.target.value));

  return (
    <div>
      <span>Font family: </span>
      <span
        className={classes.custom_wrapper}
        style={{ backgroundColor: backgroundColor, color: textColor }}
      >
        <select value={fontFamily} onChange={setFontFamily} className={classes.select}>
          <option value="'Inconsolata', monospace">Inconsolata</option>
          <option value="'Quintessential', cursive">Quintessential</option>
          <option value="'Bebas Neue', cursive">Bebas Neue</option>
        </select>
      </span>
    </div>
  );
};
