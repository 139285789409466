import { Fragment, useState, useEffect } from 'react';
import { stylesActions } from '../../../store/stylesSlice';
import { ColorInput } from '../../ColorInput/ColorInput';
import { SettingsButton } from './SettingsButton';
import { FontFamilyInput } from '../../FontFamilyInput/FontFamilyInput';
import classes from './Settings.module.css';
import { useAppDispatch, useAppSelector } from '../../../store';

export const Settings = () => {
  const dispatch = useAppDispatch();

  const backgroundColor = useAppSelector((state) => state.styles.backgroundColor);
  const textColor = useAppSelector((state) => state.styles.textColorOnBackground);

  const [currentBackgroundColor, setCurrentBackgroundColor] = useState(backgroundColor);
  const [currentTextColor, setCurrentTextColor] = useState(textColor);
  const [settingsIsOpened, setSettingsIsOpened] = useState(false);

  const dispatchBackgroundColor = () =>
    dispatch(stylesActions.setBackgroundColor(currentBackgroundColor));

  const dispatchTextColorOnBackground = () =>
    dispatch(stylesActions.setTextColorOnBackground(currentTextColor));

  useEffect(() => {
    const dispatchDataIdentifier = setTimeout(() => {
      dispatchBackgroundColor();
      dispatchTextColorOnBackground();
    }, 500);
    return () => {
      clearTimeout(dispatchDataIdentifier);
    };
  });

  return (
    <Fragment>
      <SettingsButton
        settingsIsOpened={settingsIsOpened}
        setSettingsIsOpened={setSettingsIsOpened}
      />
      <div
        className={`${classes.menu} ${settingsIsOpened ? classes.opened : ''}`}
        style={{ color: textColor, backgroundColor: backgroundColor }}
      >
        <span>
          <ColorInput
            currentColor={currentTextColor}
            setColor={setCurrentTextColor}
            title={'Text color on background'}
          />
        </span>
        <span>
          <ColorInput
            currentColor={currentBackgroundColor}
            setColor={setCurrentBackgroundColor}
            title={'Background color'}
          />
        </span>
        <span>
          <FontFamilyInput />
        </span>
      </div>
    </Fragment>
  );
};
