import classes from './Button.module.css';
import { FC } from 'react';
import { useAppSelector } from '../../store';

interface ButtonProps {
  onClick: () => void;
  children: string | JSX.Element;
  className?: string;
}

export const Button: FC<ButtonProps> = ({ onClick, children, className }) => {
  const backgroundColor = useAppSelector((state) => state.styles.backgroundColor);
  const textColor = useAppSelector((state) => state.styles.textColorOnBackground);

  const styles = { backgroundColor: backgroundColor, color: textColor, fontFamily: 'inherit' };

  return (
    <button
      className={`${classes.button} ${className}`}
      onClick={onClick}
      style={styles}
      type="button"
    >
      {children}
    </button>
  );
};
