import { useState, useEffect, useCallback } from 'react';
import classes from './Quotes.module.css';

interface Quote {
  text: string;
  author: string;
}

export const Quotes = () => {
  const [quotes, setQuotes] = useState<Quote[]>();
  const [currentQuote, setCurrentQuote] = useState<Quote>();

  const getRandomValueFromArray = (arrayLength: number) => Math.floor(Math.random() * arrayLength);

  const fetchQuotes = useCallback(async () => {
    const response = await fetch('https://type.fit/api/quotes');
    const convertedQuotes = await response.json();
    if (!convertedQuotes) return;
    setQuotes(convertedQuotes);
    setCurrentQuote(convertedQuotes[getRandomValueFromArray(convertedQuotes.length)]);
  }, []);

  useEffect(() => {
    void fetchQuotes();
  }, []);

  // Give the value in munutes
  const intervalBetweenQuotes = 5;

  useEffect(() => {
    if (!quotes) return;
    const changeQuote = setInterval(() => {
      setCurrentQuote(quotes[getRandomValueFromArray(quotes.length)]);
    }, intervalBetweenQuotes * 60000);

    return () => clearInterval(changeQuote);
  }, [quotes]);

  return <p className={classes.quote}>{currentQuote?.text}</p>;
};
