import classes from './GifButton.module.css';
import { Gif } from '../../models/Gif';

interface GifButtonProps extends Gif {
  onClick: () => void;
}

export const GifButton = ({ alt, onClick, src }: GifButtonProps) => {
  return (
    <button onClick={onClick} className={classes.gif_button}>
      <img src={src} alt={alt} className={classes.gif} />
    </button>
  );
};
